/* 
Website Resets

html, body, div, span, applet, object, iframe, table, caption, tbody, tfoot, thead, tr, th, td, 
del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, 
h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, 
dl, dt, dd, ol, ul, li, fieldset, form, label, legend {
    vertical-align: baseline;
    font-family: inherit;
    font-weight: inherit;
    font-style: inherit;
    font-size: 100%;
    outline: 0;
    padding: 0;
    margin: 0;
    }
/* remember to define focus styles! */
:focus {
    outline: 0;
    }
 
*{
    padding:0;
    margin:0;
    box-sizing:border-box;
    max-width:100%;
}

/*General Styles*/
html{
    font-size:62.5%;
}
body{
    font-size:1.4rem;
    line-height:1.5;
    color:#000000;
    font-family: 'Roboto Mono', monospace;
}
a{
    font-family: 'Roboto Mono', monospace;
    text-decoration:none;
}
h1{
    font-size:4rem;
    font-family: ‘monospace’
}
h2 {
    font-size: 1.8rem;
}
section{
    padding:4% 0;
}
li{
    list-style-type: none;
}
button:hover {
    cursor: pointer;
}

/* MEDIA QUERIES */

/* Phones:  */

@media screen and (max-width: 767px) {
    
}

/* Tablet: */

@media screen and (min-width: 768px) and (max-width: 1023px) {
    
}

/* Desktop:  */

@media screen and (min-width: 1024px){

}


@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) { 
}

/* ----------- Retina Screens ----------- */
@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (min-resolution: 192dpi) { 
}