header {
    position: sticky;
    top: 0;
    z-index: 9999;
}

.nav-bar {
    margin: 0;
    padding: 1%;
    background-color: #bbbb04;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.nav-links {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 10%;
    text-align: center;
}

.nav-links li {
    list-style-type: none;
}

.nav-links li:hover {
    cursor: pointer;
}

.nav-bar .logo {
    border: 1px solid;
    width: 260px;
}

@media (max-width: 768px) {
    header {
        position: sticky;
        top: 0;
    }
    .nav-bar {
        justify-content: center;
    }
    .nav-links {
        width: 20%;
    }
}