.orders {
  display: flex;
}

.orders-container {
  width: 100%;
}

.orders-time {
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 70%;
}

.orders-header {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin: 3%;
  padding: 1%;
  text-align: center;
  background-color: #007bff;
  border-radius: 8px;
}

.orders-container .order-row {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin: 3%;
  border-bottom: 1px solid;
  padding-bottom: 1.5%;
  text-align: center;
}

.orders-container .order-row button {
  margin: auto;
  width: 100px;
}

.orders-container .order-row a {
  margin: auto;
}

.order-row button:hover {
  cursor: pointer;
}

.orders-container .order-row div {
  display: flex;
}

.no-orders {
  text-align: center;
}

/* ORDER MODAL  */

.order-modal {
  display: flex;
}

.order-modal .order-modal-container {
  padding: 1.5%;
  width: 100%;
}

.order-modal .order-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-modal .order-date {
  font-size: 2rem;
  text-align: end;
}

.order-modal .order-details {
  margin-top: 7%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.order-modal .order-details-header {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  text-align: center;
  border-bottom: 1px solid;
  border-radius: 8px;
  background-color: #007bff;
}

.order-modal .order-item {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  font-size: 1.5rem;
  text-align: center;
  margin: 2% 0;
}

.order-options {
  display: flex;
  justify-content: center;
  gap: 20px;
  max-width: 45%;
  margin: auto;
  margin-top: 5%;
  position: relative;
}

.order-options button {
  padding: 1.5%;
  font-size: 1.5rem;
}

.order-options .accept {
  background-color: green;
  color: whitesmoke;
}

.order-options .reject {
  background: red;
  color: whitesmoke;
}

.reject-options {
  position: absolute;
  top: 80px;
  left: 160px;
  width: 50%;
}

.doordash-tracking {
 background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
}

.doordash-tracking h2 {
  font-size: 1.5rem;
  color: #333;
  margin: 0;
}

.doordash-tracking a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.doordash-tracking a:hover,
.doordash-tracking a:focus {
  color: #0056b3;
  text-decoration: underline;
}

.canceled-message {
  text-align: center;
}

@media (max-width: 768px) {
  .orders-container .order-row {
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
  }
  .orders-header {
    grid-template-columns: repeat(3, 1fr);
  }
  .order-modal .order-name {
    display: block;
  }
  .order-modal .order-details-header {
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
  }
  .order-modal .order-item {
    grid-template-columns: repeat(4, 1fr);
  }
  .order-options .reject {
    background: red;
    color: whitesmoke;
  }
  .reject-options {
    position: absolute;
    top: 40px;
    left: 0px;
    width: 100%;
  }
}
