.verify-password {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  background-color: #f9f9f9;
  padding: 20px;
}

.verify-password-form {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 30px;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.verify-password h1 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.verfy-password-container {
  margin-bottom: 15px;
}

.verfy-password-container label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.verify-password .userInput {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.verify-password .userInput:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.2);
}

.verify-password button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.verify-password button:hover {
  background-color: #0056b3;
}

.verify-password .error-message {
  color: #d9534f;
  font-size: 0.9rem;
  margin-top: 10px;
  text-align: center;
}

.verify-password p {
  color: #555;
  font-size: 0.9rem;
  text-align: center;
}
