.settings {
    display: flex;
}

.settings-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
}

.settings-container .update-profile-info {
    height: 200px;
    width: 100%;
    margin-bottom: 1.5%;
}

.profile-container {
    max-width: 600px;
    width: 100%;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.profile-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.profile-header h1 {
    font-size: 24px;
    color: #333;
    margin: auto;
}

.profile-picture {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}

.profile-details {
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.profile-detail {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.profile-detail label {
    font-weight: bold;
    color: #555;
}

.profile-detail span {
    color: #333;
}

/* UPDATE FORM  */

.update-form-exit {
    cursor: pointer;
}

.update-form {
    position: absolute;
    top: 60px;
    max-width: 1000px;
    width: 85%;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.update-form form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.update-form form input {
    width: 100%;
    padding: 1%;
    margin-bottom: 1%;
}

.update-form button {
    width: 30%;
    background-color: royalblue;
    border-radius: 10px;
    margin: auto;
    padding: 1%;
}

.update-form select {
    width: 100%;
    padding: 1%;
    margin-bottom: 1%;
}

.profile-container button {
    padding: 10px;
    border-radius: 10px;
    border: 1px solid;
}

.stripe-redirect {
    margin-top: 1.5%;
    padding: 10px 20px;
    width: 20%;
    text-align: center;
    background-color: royalblue;
    color: whitesmoke;
    border-radius: 10px;
    border: 1px solid;
}

@media (max-width: 768px) {
    .stripe-redirect {
        width: auto;
        margin-bottom: 5%;
    }
    .profile-container {
        padding: 5%;
    }
}