.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-container form {
  border: 1px solid;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 2%;
  gap: 10px;
  max-width: 360px;
  background-color: white;
}

.login-container form button {
  background: black;
  padding: 3%;
  color: whitesmoke;
  border-radius: 10px;
  font-weight: 900;
}

.login-container form input {
  background-color: whitesmoke;
  border-radius: 10px;
}

.login-credentials {
  display: flex;
  flex-direction: column;
}

.login-container .name {
  color: #f0904a;
}

@media (max-width: 768px) {
  .login-container {
    flex-direction: column;
  }
  .login-container form {
    width: 100%;
    max-width: 90%;
    margin: 1%;
  }
}
