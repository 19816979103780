.verified-navigation{
    height: 100vh;
    width: 10%;
    position: sticky;
    top: 0;
}

.verified-nav-bar {
    width: 100%;
    height: 100%;
    background-color: #bbbb04;
    border-right: 1px solid;
}

.verified-nav-links {
    padding: 10% 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 10%;
    /* width: 90%; */
    margin: auto;
}

.verified-nav-links li {
    display: flex;
    align-items: center;
    gap: 10px;
    color: rgb(0, 0, 0);
}

.verified-sub-nav {
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
    width: 100%;
}

@media (max-width: 768px) {
    .verified-navigation {
        height: 100vh;
    }
    .verified-nav-bar {
        width: 100px;
        height: 100%;
    }
    .verified-sub-nav {
        flex-direction: row;
        gap: 10px;
    }
}