.support {
    display: flex;
}

.support-container {
    margin: auto;
}

.support-page {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
}

.support-header h1 {
    font-size: 24px;
    margin-bottom: 10px;
}

.support-header p {
    font-size: 16px;
}

.support-form-container {
    margin-top: 20px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.error-message {
    color: red;
    font-size: 12px;
}

.success-message {
    color: green;
    font-size: 14px;
    margin-bottom: 10px;
}

.submit-btn {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.support-options {
    margin-top: 20px;
}

.support-options h3 {
    margin-bottom: 5px;
}

.support-options p {
    margin: 5px 0;
}
  