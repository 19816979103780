.landing-page {
  width: 100%;
}
.progress-step-documents-container {
  padding-top: 3%;
  width: 90%;
  margin: auto;
  text-align: center;
}

.stripe-container {
  width: 40%;
  margin: auto;
  text-align: center;
}

.ContentFooter {
  text-align: center;
}

.submission-steps {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid;
  padding: 2%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
  width: 100%;
}

.submission-steps .step {
  width: 75%;
}

.submission-steps .step li {
  list-style-type: square;
  margin-left: 5%;
}

.submission-steps a {
  margin-left: 5%;
}

.name-flex {
  display: flex;
}

/* General Styles */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #bbbc21;
}

.logo {
  font-size: 1.8rem;
  font-weight: bold;
  color: #fff;
}

.nav {
  display: flex;
  gap: 1rem;
}

.nav-link {
  color: white;
  font-weight: 500;
}

.cta-button {
  padding: 0.6rem 1.2rem;
  background-color: #fff;
  color: #bbbc21;
  border: 1px solid white;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.cta-button:hover {
  background-color: #333;
  color: #fff;
}

.hero {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  padding: 5rem 1rem;
  background: url("https://images.unsplash.com/photo-1568658176307-bfbd2873abda?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGJ1c2luZXNzJTIwb3duZXJzfGVufDB8fDB8fHww")
    center center / cover no-repeat;
  color: white;
}

.already-a-partner {
  color: rgb(152, 79, 220);
}

.already-a-partner:hover {
  cursor: pointer;
}

.sign-up-form {
  width: 100%;
  min-height: 70vh;
}

.hero-content {
  width: 50%;
}

.hero h2 {
  font-size: 2.8rem;
  margin-bottom: 1rem;
  color: whitesmoke;
}

.progress-step-documents-container .hero p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.hero-button {
  padding: 0.8rem 1.5rem;
  background-color: #fff;
  color: #bbbc21;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  width: 30%;
}

.hero-button:hover {
  background-color: #333;
  color: white;
}

.hero form {
  color: black;
}

.hero form input {
  padding: 1.5%;
  color: black;
}

.setting-input-container {
  display: flex;
  flex-direction: column;
}

.benefits {
  text-align: center;
  padding: 3rem 1rem;
  background-color: #f3f4f6;
  width: 75%;
  margin: auto;
}

.benefits h2 {
  color: #333;
  margin-bottom: 2rem;
}

.benefits-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.benefit {
  padding: 1.5rem;
  background-color: white;
  border-radius: 10px;
  text-align: center;
  transition: box-shadow 0.3s;
}

.benefit:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.benefit h3 {
  color: #bbbc21;
  margin-bottom: 0.5rem;
}

.benefit p {
  color: #666;
}

.benefit-image {
  width: 30%;
  border-radius: 8px;
  margin-bottom: 1rem;
}

/* ContactSection.css */

.contact-section {
  padding: 50px;
  background-color: #9f9f9f;
  text-align: center;
  border-top: 2px solid #bbbc21;
}

.contact-section h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 10px;
}

.contact-section p {
  color: #555;
  font-size: 1.3rem;
  margin-bottom: 30px;
}

.contact-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.contact-info {
  flex: 1;
  text-align: left;
  max-width: 300px;
}

.contact-info h3 {
  font-size: 1.2rem;
  color: #333;
}

.contact-info p {
  margin: 8px 0;
}

.contact-info a {
  color: #bbbc21;
  text-decoration: none;
}

.contact-form {
  flex: 1;
  width: 400px;
  max-width: 400px;
}

.contact-form h3 {
  font-size: 1.3rem;
  color: #333;
  margin-bottom: 10px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.contact-form button {
  background-color: #bbbc21;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #a3a416;
}

.social-links {
  margin-top: 40px;
}

.social-links h3 {
  font-size: 1.2rem;
  color: #333;
}

.social-links a {
  color: #bbbc21;
  margin: 0 10px;
  text-decoration: none;
  font-size: 1.2rem;
}

.social-links a:hover {
  color: #a3a416;
}

.store-setup-container {
  padding: 2rem;
  max-width: 600px;
  margin: auto;
}

.store-setup-container h1 {
  font-size: 3.2rem;
  padding-bottom: 2%;
  text-align: center;
}

.progress-indicator {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.step {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ccc;
  color: white;
}

.completed {
  background-color: #4caf50;
}

.line {
  flex-grow: 1;
  height: 4px;
  background-color: #ccc;
}

.store-setup-steps {
  margin-top: 1.5rem;
}

.store-details,
.stripe-setup {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.details-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.arrow {
  font-size: 1.5rem;
  color: #4caf50;
}

.setup-button {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 0.5rem;
  width: 100%;
}

.setup-button:hover {
  background-color: #45a049;
}

.partner-tiers {
  text-align: center;
  padding: 2rem;
}

.tier-container {
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: center;
  margin-top: 1rem;
}

.tier {
  height: 65vh;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.basic-tier {
  border-top: 5px solid #6c757d;
}

.premium-tier {
  border-top: 5px solid #ff6f00;
}

.tier h3 {
  color: #333;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.tier ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0;
  color: #555;
  font-size: 0.9rem;
}

.tier ul li {
  font-size: 1.4rem;
  margin: 0.5rem 0;
}

.choose-button {
  margin-top: 1rem;
  padding: 0.5rem 1.5rem;
  font-size: 1.3rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.choose-button:hover {
  background-color: #0056b3;
}

.store-operations {
  max-width: 900px;
  width: 800px;
  margin: auto;
  margin-top: 1%;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.store-operations h1 {
  text-align: center;
  font-size: 24px;
  color: #333;
}

.store-operations h2 {
  font-size: 20px;
  color: #4c524c;
  margin-bottom: 10px;
}

.store-operations .form-input,
.form-textarea,
.form-select,
.form-input-file {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

.store-operations .form-input:focus,
.form-textarea:focus,
.form-select:focus {
  border-color: #4caf50;
  outline: none;
}

.store-operations .form-textarea {
  height: 100px;
}

.store-operations .submit-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.store-operations .submit-button:hover {
  background-color: #45a049;
}

.store-operations .form-input-file {
  padding: 0;
  border: none;
}
.operating-hours {
  margin: 20px 0;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.operating-hours h2 {
  font-family: "Roboto", sans-serif;
  color: #333;
}

.day {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.day label {
  flex: 1;
  font-weight: bold;
  color: #555;
}

.day select {
  width: 40%;
  margin-right: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.store-setup-next-container {
  text-align: center;
}

.store-setup-next {
  width: 50%;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: #45a049;
  font-weight: 900;
}

@media (max-width: 768px) {
  .header {
    padding: 1rem;
    flex-direction: column;
    gap: 1rem;
  }
  .hero {
    padding: 2rem 1rem;
    flex-direction: column;
  }
  .hero-content {
    width: 100%;
  }
  .benefits {
    border: 1px solid;
    width: 100%;
  }
  .store-operations {
    max-width: 100%;
    width: 100%;
    margin-bottom: 20%;
  }
  .stripe-container {
    width: 90%;
    margin: auto;
    text-align: center;
  }
  .basic-tier {
    width: 90%;
  }
  .progress-step-documents-container h1 {
    font-size: 2.5rem;
    margin-top: 5%;
  }
  .contact-section {
    padding: 10%;
  }
  .contact-details form {
    margin: auto;
  }
}
