body {
  font-family: "Arial", sans-serif;
  background-color: #f4f4f9;
  color: #333;
}

.inventory {
  display: flex;
  background-color: #fff;
  min-height: 100vh;
}

.inventory-container {
  width: 90%;
  background-color: #fdfdfd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.inventory-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.inventory-header h1 {
  font-size: 2.5em;
  color: #333;
}

.inventory-header a {
  background-color: #007bff;
  text-align: center;
  width: 20%;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.inventory-header a:hover {
  background-color: #0056b3;
}

.product-headers {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  text-align: center;
  gap: 20px;
  padding: 10px 0;
  font-size: 1.2em;
  background-color: #007bff;
  color: #fff;
  border-radius: 8px;
}

.item {
  padding: 10px 0;
}

.inventory-products {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  text-align: center;
  gap: 20px;
  margin: 10px 0;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  text-decoration: none;
  color: inherit;
}

.inventory-products:hover {
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  transform: translateY(-5px);
}

.inventory-products img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 10px;
  margin: auto;
}

.inventory-products p {
  margin: 0;
  font-size: 1.1em;
  align-self: center;
}

.inventory-products .active {
  background-color: #28a745;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
}

.inventory-products .inActive {
  background-color: red;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
}

.no-products {
  text-align: center;
  font-size: 1.5em;
  margin-top: 50px;
  color: #666;
}

/* ADD PRODUCTS PAGE  */

.add-product {
  display: flex;
}

.form-container {
  width: 70%;
  margin: 0 auto;
  padding: 20px;
  background: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.form-container h2 {
  text-align: center;
  color: #333;
}

.form-group {
  margin-bottom: 20px;
  height: 100%;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

textarea {
  resize: vertical;
}

.submit-btn {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #0056b3;
}

/* PRODUCT MODAL  */

.product {
  width: 100%;
  margin-left: 2%;
}

.product-modal {
  display: flex;
}

.product-modal-container {
  width: 80%;
}

.product-modal-container .item {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.product-modal-container .form-container form {
  width: 90%;
}

.product-modal-container .item-image {
  overflow-x: scroll;
  display: flex;
  gap: 5%;
  margin-bottom: 3%;
}

.product-modal-container .item-image img {
  width: 100%;
  height: 80%;
}

@media (max-width: 768px) {
  .inventory-container {
    overflow-x: hidden;
    width: 90%;
    padding: 1%;
  }
  .product-headers {
    grid-template-columns: repeat(3, 1fr);
    font-size: 1em;
    padding: 2%;
  }

  .inventory-products {
    grid-template-columns: repeat(3, 1fr);
    font-size: 1em;
  }

  .form-container h2 {
    margin: 10px 0;
  }

  .inventory-products img {
    width: 60px;
    height: 60px;
  }

  .inventory-header a {
    width: auto;
  }

  .product-modal-container .form-container form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .product-modal-container {
    width: 85%;
  }

  .form-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1%;
  }

  .product-modal-container .item {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .product-modal-container .item-image {
    margin: 10px 0;
    width: 100%;
    overflow-x: scroll;
  }

  .product-modal-container .item-image-container {
    text-align: center;
    width: 100px;
    height: 150px;
  }

  .product-modal-container .item-image img {
    height: 75%;
  }
}
